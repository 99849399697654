import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,//import here} from "./utils/interact.js";
  mintMultiple,
  checkWizards
} from "./utils/interact.js";
import FrostBlade from 'D:/NFT_development/front_end/new-app-pages/wizardao/nft-minter-tutorial-main/minter-starter-files/src/WIZ.gif'
import pixagif from 'D:/NFT_development/front_end/new-app-pages/wizardao/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/wizardao/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
const Minter = (props) => {

  

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  let [name, setName] = useState("");
  const [wizstatus, setWizStatus] = useState("");
 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    
        
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { //TODO: implement
      const { status } = await mintNFT();
    setStatus(status);  
  };

  const onMintLoopPressed = async () => { //TODO: implement
      const { status } = await mintMultiple();
    setStatus(status);  
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkWizards(name);
    setWizStatus(wizstatus);  
  };

  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>
<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>

<div id="menubar">
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
</div>          

<button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <h1>🎩  The  🎩</h1>
      <h1>WizarDAO </h1>
      <h1>_________ </h1>
      <h4> Every PixaWizard NFT you own entitles you to 1 free WizarDAO token claim, which allows you to vote on major decisions in the PixaRealm, as well as allocating $PIXA from the DAO to funded community projects. The WizarDAO token artwork was designed by Tim Riopelle, an emmy award-winning digital artist. These are ERC721 tokens that are limited to 1000 total supply, if all PixaWizards claim their WizarDAO NFT. </h4>
 
<h1>
      <div>
      <img id="image" src={FrostBlade} alt="Image" height={250} width={250} border={10}/>
      </div>
      </h1>

      <h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


<h1>Check</h1>
<h1>_________</h1>
   
      <h4 id="title">Enter PixaWizard TokenID to check claim status: </h4>
      <h5 id = "header33">Make sure you are entering the PixaWizard token ID, NOT the PixaWizard #. You can check the token ID for a PixaWizard NFT under the "details" tab on <a href="https://opensea.io/collection/pixawizards" rel="noreferrer">  OpenSea</a> </h5>


        <input id="entry"
          type="number"
          placeholder="Token ID"
          onChange={(event) => setName(event.target.value)}
        />
      <button id="checkButton" onClick={onCheckPressed}>
        Check
      </button>

        <p id="wizstatus">
        {wizstatus}
              </p>



              <h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

      <h1 >Claim</h1>
<h1>_________</h1>
      <h4 >Claim your WizarDAO tokens by minting them below. You may either mint 1 token at a time, or mint all WizarDAO tokens you are entitled to.</h4>
   
 
      <button id="mintButton" onClick={onMintPressed}>
        Mint One
      </button>
      
      <button id="mintButton2" onClick={onMintLoopPressed}>
        Mint All
      </button>
  
      <h4 > Check out your new WizarDAO NFT on <a href="https://opensea.io/collection/the-wizardao" rel="noreferrer">  OpenSea </a></h4>

   
      <h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h3>Important Links:</h3>

<h4><a href="https://etherscan.io/address/0xbD16C356e4dea7b0bB821b853F684ddEB8Dd1620" rel="noreferrer">  WizarDAO Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>
  

    </div>
  );
};



export default Minter;
